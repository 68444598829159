<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-group-message-96.png"
          class="h-20 ml-2 inline-block"
        />
        مشخصات پست
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <!-- ANCHOR - post info -->
      <div class="bg-blueGray-100 border border-blueGray-200 rounded-md">
        <div class="bg-teal-600 text-teal-50 p-3">مشخاصت پست : {{ post.id }}</div>
        <div class="flex p-2 odd:bg-blueGray-200">
          <div class="w-1/3">کاربر ثبت کننده</div>
          <div>
            {{ post?.user?.first_name }} {{ post?.user?.last_name }} ({{
              post?.user?.username
            }})
          </div>
        </div>
        <div class="flex p-2 odd:bg-blueGray-200">
          <div class="w-1/3">نوع پست</div>
          <div>{{ post.file_type }}</div>
        </div>
        <div class="flex p-2 odd:bg-blueGray-200" v-if="post.send_on">
          <div class="w-1/3">زمان ارسال</div>
          <div>
            {{ post.send_on?.fa }}
          </div>
        </div>
        <div class="flex p-2 odd:bg-blueGray-200">
          <div class="w-1/3">زمان ارسال شده</div>
          <div>
            {{ post.sended_at?.fa }}
          </div>
        </div>
        <div class="flex p-2 odd:bg-blueGray-200">
          <div class="w-1/3">زمان ایجاد پست</div>
          <div>
            {{ post.created_at?.fa }}
          </div>
        </div>
        <div class="flex p-2 odd:bg-blueGray-200">
          <div class="w-1/3">زمان آخرین تغییر</div>
          <div>
            {{ post.updated_at?.fa }}
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    BodySimple,
    Icon,
  },
  data() {
    return {
      firstLoading: true,
      error: [],
      channel: {},
      post: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get(
          `api/owner-channels/${$this.$route.params.channelId}/post/${$this.$route.params.postId}/info`,
          {
            params: {
              id: $this.$store.state.user.id,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.post = response.data.post;
          // $this.setValues(response);
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
